<template>
  <b-card class="roadmap-edit-page">
    <upload-file @pushFile="pushFile" />
    <b-col>
      <b-row v-if="loading">
        <b-col class="mt-1-5 text-center">
          <b-spinner label="Loading..." />
        </b-col>
      </b-row>
      <b-row
        v-if="files.length > 0 && !loading"
        class="w-100"
        no-gutters
      >
        <b-col
          cols="5"
          class="mt-1 pl-1"
        >
          {{ files.length }} File(s) found
        </b-col>
        <b-list-group class="mt-1 w-100">

          <draggable
            :list="files"
            handle=".drag-handle"
            @end="onDrop"
          >
            <b-list-group-item
              v-for="(file, index) in files"
              :key="index"
              class="d-flex justify-content-between align-items-center image-item"
            >
              <div>
                <img
                  v-b-tooltip="file.title"
                  :src="file.file"
                  class="mr-1 image-responsive rounded-circle"
                  width="75px"
                  height="75px"
                >
                <span class="file-data">
                  <span class="mr-auto ml-1">{{ file.title }}</span>
                  <span class="mr-auto ml-1">{{ file.size }}</span>
                  <span class="mr-auto ml-1">{{ file.created_at }}</span>
                </span>
              </div>
              <b-row>
                <b-col
                  cols="2"
                  class="mt-1-5"
                >
                  <div
                    v-if="file.loading !== 'undefined' && file.loading"
                    class="row-loading-spinner"
                  >
                    <b-spinner label="Loading..." />
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  class="mt-1-5"
                >
                  <input
                    type="checkbox"
                    :value="file.id"
                    :checked="file.is_selected"
                    :data-index="index"
                    class="checkbox-item"
                    @change="changeSelected"
                  >
                </b-col>
                <b-col cols="3">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <div class="large-text">
                        &#x2699;<span class="sr-only">Search</span>
                      </div>
                    </template>
                    <b-dropdown-item
                      v-b-tooltip="'Open this file'"
                      @click="() => openFile(file)"
                    >
                      <feather-icon
                        class="large-text mr-1"
                        icon="ExternalLinkIcon"
                      />Open
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-b-tooltip="'Delete this file'"
                      @click="() => deleteFile(file,index)"
                    >
                      <feather-icon
                        class="large-text mr-1"
                        icon="XIcon"
                      />Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <b-col
                  cols="1"
                >
                  <feather-icon
                    v-b-tooltip="'Move this item to reorder it'"
                    class="large-text mr-3 drag-handle mt-1-5"
                    icon="MoveIcon"
                  />
                </b-col>

              </b-row>
            </b-list-group-item>
          </draggable>

        </b-list-group>
      </b-row>
      <b-row v-if="files.length <= 0 && !loading">
        <b-col class="mt-1-5 text-center">
          <b>No files matching Your criteria</b>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>

import { BRow, BCol, BCard } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import UploadFile from './components/uploadfile.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    UploadFile,
    draggable,
  },
  data() {
    return {
      files: [],
      loading: false,
    }
  },
  mounted() {
    this.loadingRoadMapFiles()
  },
  methods: {
    pushFile(file) {
      this.files.push({
        id: file.id,
        title: file.title,
        file: file.file,
        size: file.size,
        uploaded_date: file.created_at,
        selected: file.selected,
      })
    },
    changeSelected(event) {
      const { value } = event.target

      const index = event.target.getAttribute('data-index')
      const newStatus = event.target.checked ? '1' : '0'

      this.files[index].loading = true

      this.$http.post(this.$resources.roadmap.files.changeStatus.replace(':id', value), {
        new_status: newStatus,
      }).then(() => {
        this.files[index].loading = false
      })
    },
    openFile(file) {
      window.open(file.file)
    },
    deleteFile(file, fileIndex) {
      this.$swal({
        title: `Delete ${file.title} file?`,
        text: `Would you like to block this ${file.title} photo?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => { // <--
        if (result.value) { // <-- if confirmed
          this.$http.delete(this.$resources.roadmap.files.destroy.replace(':id', file.id))
            .then(() => {
              this.files.splice(fileIndex, 1)
            })
        }
      })
    },
    loadingRoadMapFiles() {
      this.loading = true

      this.$http.get(this.$resources.roadmap.files.index).then(response => {
        this.files = response.data.data.files

        this.loading = false
      })
    },
    onDrop() {
      const newOrderArray = this.files.map((file, index) => ({ id: file.id, order_index: index + 1 }))

      this.$http.post(this.$resources.roadmap.files.reorder, { new_order_array: newOrderArray })
    },
  },
}
</script>

<style lang="scss" scoped>
.roadmap-edit-page {
  .row-loading-spinner {
    text-align: center;
  }
  .spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
  }
  .b-avatar {
    width: 100% !important;
    height: 100% !important;
  }
  .large-text {
    font-size: 2rem;
  }
  .mt-1-5{
    margin-top: 1.5rem !important;
  }
  .checkbox-item {
    width: 1.285rem;
    height: 1.285rem;
    background-color: #ccc;
    border-color: #ccc;
  }
  .checkbox-item:checked {
    background-color: #ccc;
    border-color: #ccc;
  }
  .drag-handle {
    cursor: move;
  }
  .btn-group {
    border: none !important;
  }
}
</style>
