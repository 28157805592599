<template>
  <b-row no-gutters>
    <validation-observer
      ref="refFileObserver"
      class="w-100"
    >
      <validation-provider
        #default="validationContext"
        name="title"
        rules="required|max:191"
      >
        <b-form-group
          label="File title"
          label-for="filetitle"
        >
          <b-form-input
            id="filetitle"
            v-model="fileTitle"
            :state="validationContext.errors.length > 0 ? false:null"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="fileValidationContext"
        name="file"
      >
        <b-form-group
          label="File"
          label-for="file"
        >
          <input
            id="file"
            ref="fileInput"
            name="file"
            type="file"
            :class="fileValidationContext.errors.length > 0 ? 'col-12 form-control is-invalid' : 'col-12 form-control'"
            accept=".jpeg,.jpg,.png"
            :state="fileValidationContext.errors.length > 0 ? false:null"
            @change="selectFile"
          >
          <b-form-invalid-feedback>
            {{ fileValidationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div
        v-if="currentFile"
        class="progress mb-1 mt-1"
      >
        <div
          class="progress-bar progress-bar-info"
          role="progressbar"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: progress + '%' }"
        >
          {{ progress }}%
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary float-right"
        @click="upload"
      >Upload</button>
    </validation-observer>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ProgressiveUploader from '@/libs/progressive-uploader'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
  },
  data() {
    return {
      currentFile: null,
      previewFile: null,
      fileTitle: '',
      progress: 0,
    }
  },
  methods: {
    upload() {
      const fileData = {
        file: this.currentFile,
        title: this.fileTitle,
      }
      this.$refs.refFileObserver.validate()
        .then(success => {
          if (success) {
            const formData = new FormData()

            formData.append('file', fileData.file)
            formData.append('title', fileData.title);

            (new ProgressiveUploader(this.$resources.roadmap.files.store)).upload(formData, event => {
              this.progress = Math.round((100 * event.loaded) / event.total)
            }).then(response => {
              const { file } = response.data.data

              this.$refs.refFileObserver.reset()

              this.fileTitle = ''

              this.$refs.fileInput.value = null

              this.$emit('pushFile', file)
            }).catch(err => {
              this.$refs.refFileObserver.setErrors(err.response.data)
            })
          }
        })
    },
    selectFile(e) {
      this.currentFile = e.target.files[0]
      this.previewFile = URL.createObjectURL(this.currentFile)
    },
  },
}
</script>
