import Http from '@/libs/axios'

class ProgressiveUploader {
  constructor(api) {
    this.api = api
  }

  upload(form, onUploadProgress) {
    return Http.post(this.api, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }
}

export default ProgressiveUploader
